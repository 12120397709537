<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>Lists</h2>
            <div class="box-tools pull-right">
              <button type="button" class="button" data-toggle="modal" data-target="#listModal">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Create List</span>
              </button>
              <router-link to="/search-lists" type="button" class="button">
                <span class="icon"><i class="mdi mdi-magnify" /></span> <span>Search</span>
              </router-link>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div class="tabs">
              <ul class="is-marginless">
                <li id="schemelistsTab" role="presentation" :class="{'is-active': selectedTabIndex === 0}">
                  <a href="#" role="tab" data-toggle="tab" @click="getSchemeListData">Spelling {{ $store.state.user.locale === 'en_US' ? 'Curriculum' : 'Scheme' }}</a>
                </li>
                <li id="mylistsTab" role="presentation" :class="{'is-active': selectedTabIndex === 1}">
                  <a href="#" role="tab" data-toggle="tab" @click="getListData">Your Lists</a>
                </li>
                <li id="favlistsTab" role="presentation" :class="{'is-active': selectedTabIndex === 2}">
                  <a href="#" role="tab" data-toggle="tab" @click="getFavouriteListData">{{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }} Lists</a>
                </li>
                <li v-for="(variant, index) in schemeVariants" :key="'variant-' + variant" :class="{'is-active': selectedTabIndex === 3 + index}">
                  <a href="#" role="tab" data-toggle="tab" @click="getSchemeVariantListData(variant, index + 3)">{{ getSchemeVariantTitle(variant) }}</a>
                </li>
              </ul>
            </div>
            <div v-if="listData" class="col-sm-12 table-responsive">
              <div v-if="selectedTabIndex === 0">
                <p style="margin-bottom: 10px;">
                  Viewing our {{ $store.state.user.locale === 'en_US' ? 'curriculum' : 'scheme' }} for <b>{{ getRegionName() }}</b>. You can change your school's region in <router-link to="/school">
                    school settings.
                  </router-link>
                </p>
                <form>
                  <div class="field is-horizontal is-12">
                    <div class="field-label is-normal">
                      <label class="label">Stage</label>
                    </div>
                    <div class="field-body">
                      <div class="field is-fullwidth">
                        <div class="control">
                          <div class="select is-fullwidth">
                            <select v-model="stageFilter" @change="stageDidChange()">
                              <option value="-1">
                                All
                              </option>
                              <option value="0">
                                Phonics
                              </option>
                              <option value="1">
                                Stage 1
                              </option>
                              <option value="2">
                                Stage 2
                              </option>
                              <option value="3">
                                Stage 3
                              </option>
                              <option value="4">
                                Stage 4
                              </option>
                              <option value="5">
                                Stage 5
                              </option>
                              <option v-if="$store.state.user.school.locale != 'en_US'" value="6">
                                Stage 6
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <hr>
              </div>

              <b-table
                :data.sync="filteredData"
                :paginated="true"
                :per-page="10"
                :mobile-cards="true"
                :striped="true"
                :selectable="false"
                :sort="false"
              >
                <template slot-scope="props">
                  <b-table-column class="content" field="title" label="List">
                    <h4>{{ props.row.title }} <span v-if="props.row.is_phonics" class="tag is-success">PHONICS</span></h4>
                    <p v-if="props.row.favourite == 1 && (!props.row.scheme || selectedTabIndex > 0)">
                      <router-link :to="'/users/' + props.row.owner">
                        <i>@{{ props.row.owner }}</i>
                      </router-link>
                    </p><p>{{ props.row.word_count || 0 }} Word(s) <span v-if="props.row.favourite == 1">❤️</span></p>
                  </b-table-column>
                  <b-table-column field="stage" label="Stage" :visible="selectedTabIndex == 0">
                    <span v-if="props.row.stage != null && props.row.stage > 0">Stage {{ props.row.stage }}</span>
                    <span v-if="props.row.stage != null && props.row.stage == 0">Phonics</span>
                  </b-table-column>
                  <b-table-column field="list" label="Step" :visible="selectedTabIndex == 0">
                    <span v-if="props.row.list > 0">Step {{ props.row.list }}</span>
                    <span v-else-if="props.row.stage != null">Extra</span>
                  </b-table-column>
                  <b-table-column field="locale" label="Locale">
                    <span v-html="localeInfo(props.row.locale)" />
                  </b-table-column>
                  <b-table-column field="rating" label="Rating">
                    <star-rating
                      v-model="props.row.rating"
                      :item-size="18"
                      active-color="#ffdf00"
                      border-color="transparent"
                      :spacing="-5"
                      :read-only="true"
                      :show-rating="false"
                      :inline="true"
                    />
                  </b-table-column>
                  <b-table-column field="action" label="">
                    <div class="btn-group pull-right">
                      <router-link :to="'/list/' + props.row.ident" tag="button" type="button" class="btn button is-link">
                        Details
                      </router-link>
                      <button type="button" class="btn button is-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="caret" />
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul v-if="props.row.favourite == 1" class="dropdown-menu">
                        <li><a href="#" @click.prevent="duplicateList(props.row)">Duplicate</a></li>
                        <li><a href="#" @click.prevent="unFavouriteList(props.row.ident)">Remove</a></li>
                      </ul>
                      <ul v-else-if="!props.row.scheme" class="dropdown-menu">
                        <li><a href="#" @click.prevent="showEditList(props.row)">Edit</a></li>
                        <li><a href="#" @click.prevent="duplicateList(props.row)">Duplicate</a></li>
                        <li><a href="#" @click.prevent="deleteList(props.row.ident)">Delete</a></li>
                      </ul>
                      <ul v-else class="dropdown-menu">
                        <li><a href="#" @click.prevent="favouriteList(props.row.ident)">{{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }}</a></li>
                        <li><a href="#" @click.prevent="duplicateList(props.row)">Duplicate</a></li>
                      </ul>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered content">
                      <p>
                        <b-icon
                          custom-class="far"
                          pack="fa"
                          icon="frown"
                          size="is-large"
                        />
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add List Modal -->
    <div id="listModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document" style="margin: 10px auto">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="myModalLabel" class="modal-title">
              Add List
            </h4>
          </div>
          <div class="modal-body">
            <form class="ui form loginForm form-horizontal" @submit.prevent="beforeAddList">
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('name')}">
                <label for="title" class="col-sm-2 control-label">Title*</label>
                <div class="col-sm-10">
                  <input
                    v-model="name"
                    v-validate="'required'"
                    class="form-control"
                    name="name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('name')" class="help-block text-center">{{ errors.first('name') }}</span>
              </div>
              <div class="form-group col-sm-12">
                <label for="title" class="col-sm-2 control-label">Phonics*</label>
                <div class="col-sm-10">
                  <b-switch
                    v-model="isPhonics"
                    type="is-success"
                  >
                    <small v-if="isPhonics">Yes - N.B. Phonic spelling will be guessed by the system but should be checked.</small><small v-else>No</small>
                  </b-switch>
                </div>
              </div>
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('words')}">
                <label for="inputWords" class="col-sm-2 control-label">Words</label>
                <div class="col-sm-10">
                  <p><small>N.B. Words will be split by commas and new lines.</small></p>
                  <textarea
                    v-model="words"
                    v-validate="{ regex: /^[A-Za-zÀ-ÖØ-öø-ÿ\-\',\s]*$/i }"
                    class="form-control"
                    rows="3"
                    name="words"
                    @input="sanitizeText"
                  />
                </div>
                <span v-show="errors.has('words')" class="help-block text-center">{{ errors.first('words') }}</span>
                <p v-if="warnCapitals" class="has-text-danger">
                  Your words contain capital letters. Make sure capitals are only used for proper nouns.
                </p>
              </div>

              <div class="form-group col-sm-12">
                <label for="locale" class="col-sm-2 control-label">Locale *</label>
                <div class="col-sm-10">
                  <select v-model="addLocale" class="form-control">
                    <option value="en_US">
                      🇺🇸 American English
                    </option>
                    <option value="en_GB">
                      🇬🇧 British English
                    </option>
                    <option value="fr_FR">
                      🇫🇷 French
                    </option>
                    <option value="de_DE">
                      🇩🇪 German
                    </option>
                    <option value="es_ES">
                      🇪🇸 Spanish
                    </option>
                    <option value="cy_GB">
                      🏴󠁧󠁢󠁷󠁬󠁳󠁿 Welsh
                    </option>
                    <option value="pt_PT">
                      🇵🇹 Portuguese (Portugal)
                    </option>
                    <option value="pt_BR">
                      🇧🇷 Portuguese (Brazil)
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox">
                    <label>
                      <input v-model="addAnother" type="checkbox"> Add another list after saving.
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn button is-link" @click="beforeAddList">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <EditList v-if="showEdit" :list="listBeingEdited" @close="onCloseEdit" @success="didEditList" />
  </section>
  <!-- /.content -->
</template>

<script>

import $ from 'jquery'
import { StarRating } from 'vue-rate-it'
import { Validator } from 'vee-validate'
import _ from 'lodash'
import EditList from '@/components/views/components/EditList'
import store from '@/store'
import { request } from '@/edshed-common/api'

export default {
  name: 'Lists',
  components: {
    StarRating,
    EditList
  },
  data (router) {
    return {
      loading: '',
      listData: null,
      name: '',
      isPhonics: false,
      words: '',
      response: 'Data Loading...',
      addAnother: false,
      selectedTabIndex: 0,
      stageFilter: -1,
      addLocale: 'en_GB',
      showEdit: false,
      listBeingEdited: null,
      warnCapitals: false,
      schemeVariants: null
    }
  },
  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.spellingshed.com/${locale}/lists`)
    }
  },
  computed: {
    filteredData () {
      if (this.selectedTabIndex === 0 && this.stageFilter > -1) {
        return _.filter(this.listData, { stage: parseInt(this.stageFilter) })
      } else {
        return this.listData
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.$store.getters.hasSpelling) {
        this.$router.push({ path: '/' })
        return
      }
      if (this.$store.state.user.school.locale === 'en_US') {
        this.addLocale = this.$store.state.user.school.locale
      }

      this.getSchemeVariants()

      if (this.$route.query) {
        console.log('QUERY' + JSON.stringify(this.$route.query))
        console.log(this.$route.query.stage)

        if (this.$route.query.tab) {
          this.selectedTabIndex = parseInt(this.$route.query.tab, 10)
        }

        if (this.selectedTabIndex === 0) {
          this.getSchemeListData()
          if (this.$route.query.stage) {
            this.stageFilter = parseInt(this.$route.query.stage, 10)
          }
        } else if (this.selectedTabIndex === 1) {
          this.getListData()
        } else if (this.selectedTabIndex === 2) {
          this.getFavouriteListData()
        }
      }
    })
    const dictionary = {
      en_GB: {
        attributes: {
          name: 'Title',
          words: 'Words'
        }
      }
    }
    Validator.localize('en_GB', dictionary.en_GB)
  },
  methods: {
    sanitizeText (el) {
      let val = el.srcElement.value
      val = val.replace(/’/g, '\'')
      val = val.replace(/‘/g, '\'')
      val = val.replace(/`/g, '\'')
      if (val !== this.words) {
        this.words = val
      }
      if (this.words !== this.words.toLowerCase()) {
        this.warnCapitals = true
      } else {
        this.warnCapitals = false
      }
    },
    stageDidChange () {
      this.$nextTick(() => {
        if (this.stageFilter >= 0) {
          const pageUrl = '?stage=' + this.stageFilter + '&tab=0'
          window.history.pushState('', '', pageUrl)
        } else {
          window.history.pushState('', '', '?tab=0')
        }
      })
    },
    getRegionName () {
      let loc = 'England'
      if (this.$store.state.user.school.locale === 'en_AU') {
        loc = 'Australia'
      } else if (this.$store.state.user.school.locale === 'en_IE') {
        loc = 'Ireland'
      } else if (this.$store.state.user.school.locale === 'en_NZ') {
        loc = 'New Zealand'
      } else if (this.$store.state.user.school.locale === 'en_US') {
        loc = 'United States'
      }
      return loc
    },
    localeInfo (locale) {
      let loc = '<abbr title="British English">🇬🇧</abbr>'
      if (locale === 'en_US') {
        loc = '<abbr title="American English">🇺🇸</abbr>'
      } else if (locale === 'fr_FR') {
        loc = '<abbr title="French">🇫🇷</abbr>'
      } else if (locale === 'de_DE') {
        loc = '<abbr title="German">🇩🇪</abbr>'
      } else if (locale === 'es_ES') {
        loc = '<abbr title="Spanish">🇪🇸</abbr>'
      } else if (locale === 'cy_GB') {
        loc = '<abbr title="Welsh">🏴󠁧󠁢󠁷󠁬󠁳󠁿</abbr>'
      } else if (locale === 'pt_PT') {
        loc = '<abbr title="Portuguese (Portugal)">🇵🇹</abbr>'
      } else if (locale === 'pt_BR') {
        loc = '<abbr title="Portuguese (Brazil)">🇧🇷</abbr>'
      }
      return loc
    },
    getSchemeVariants () {
      request('GET', 'lists/schemes/' + this.$store.state.user.school.locale, null)
        .then((response) => {
          const data = response.data
          this.schemeVariants = data.variants.split(',').filter(v => v !== 'spellingshed')
          // console.log('readerData: ' + this.readerData)
          if (this.selectedTabIndex > 2 && this.schemeVariants.length >= this.selectedTabIndex - 2) {
            const vrnt = this.schemeVariants[this.selectedTabIndex - 3]
            this.getSchemeVariantListData(vrnt, this.selectedTabIndex)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getSchemeVariantTitle (variant) {
      switch (variant) {
      case 'animals':
        return 'Animals TEST'
      case 'dolch':
        return 'Dolch'
      case 'uil':
        return 'UIL'
      case 'uil34':
        return 'UIL Grades 3-4'
      case 'uil56':
        return 'UIL Grades 5-6'
      case 'uil78':
        return 'UIL Grades 7-8'
      case 'nzcer':
        return 'NZCER'
      case 'oxford':
        return 'Oxford Wordlists'
      case 'fry':
        return 'Fry'
      }
    },
    getListData () {
      this.selectedTabIndex = 1
      window.history.pushState('', '', '?tab=' + this.selectedTabIndex)
      // this.stageFilter = 0
      this.listData = []
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/lists', null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getFavouriteListData () {
      this.selectedTabIndex = 2
      window.history.pushState('', '', '?tab=' + this.selectedTabIndex)
      // this.stageFilter = 0
      this.listData = []
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/lists/favourites', null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // this.listData = this.listData.concat(data.lists)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getSchemeListData () {
      this.selectedTabIndex = 0
      window.history.pushState('', '', '?tab=' + this.selectedTabIndex)
      this.stageFilter = -1
      this.listData = []
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/lists/scheme', null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // this.listData = this.listData.concat(data.lists)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getSchemeVariantListData (variant, tabIndex) {
      this.selectedTabIndex = tabIndex
      window.history.pushState('', '', '?tab=' + this.selectedTabIndex)
      this.stageFilter = -1
      this.listData = []
      request('GET', 'lists/schemes/' + this.$store.state.user.school.locale + '/' + variant, null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // this.listData = this.listData.concat(data.lists)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    deleteList (ident) {
      const confirm = window.confirm('Are you sure you want to delete this list?')
      if (confirm) {
        request('DELETE', 'lists/' + ident, null)
          .then((response) => {
            this.getListData()
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    unFavouriteList (ident) {
      const confirm = window.confirm('Are you sure you want to remove this list?')
      if (confirm) {
        request('DELETE', 'lists/' + ident + '/favourite', null)
          .then((response) => {
            this.getFavouriteListData()
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    favouriteList (ident) {
      const confirm = window.confirm('Add list to ' + (this.$store.state.user.locale === 'en_US' ? 'favorites' : 'favourites') + '?')
      if (confirm) {
        request('PUT', 'lists/' + ident + '/favourite', null)
          .then((response) => {
            this.getFavouriteListData()
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    showEditList (l) {
      this.listBeingEdited = l
      this.showEdit = true
    },
    onCloseEdit () {
      this.listBeingEdited = null
      this.showEdit = false
    },
    didEditList () {
      this.listBeingEdited = null
      this.showEdit = false
      this.getListData()
    },
    beforeAddList () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addList()
        }
      })
    },
    addList () {
      const wordsprocessed = this.words.replace(/\n/g, ',') // .replace(/\s/g, ',')
      const split = wordsprocessed.split(',').map(w => w.trim()).filter(function (n) { return n !== '' })
      const cleanString = split.join(',')
      console.log(cleanString)

      if (!this.addAnother) {
        $('#listModal').removeClass('in')
        $('.modal-backdrop').remove()
        $('body').removeClass('modal-open')
      }
      request('POST', 'users/me/lists', { title: this.name, words: cleanString, locale: this.addLocale, phonics: (this.isPhonics ? 1 : 0) })
        .then((response) => {
          const list = response.data.list
          if (list) {
            list.word_count = list.words.length
            this.listData = this.listData.concat([list])
            this.name = ''
            this.words = ''
            if (this.$store.state.user.school.locale === 'en_US') {
              this.addLocale = this.$store.state.user.school.locale
            } else {
              this.addLocale = 'en_GB'
            }

            if (!this.addAnother) {
              this.$router.push('/list/' + list.ident)
            } else {
              setTimeout(() => {
                this.errors.clear()
                this.$validator.reset()
              }, 10)
            }
          } else if (response.data.error) {
            console.log(response.data.error)
            alert('Error: ' + response.data.error)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    popupAddList () {
      const listName = prompt('Enter new list name', 'My List')
      console.log(listName)
      if (listName != null) {
        request('POST', 'users/me/lists', { title: name })
          .then((response) => {
            this.listData = this.listData.concat([response.data.list])
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    duplicateList (list) { // TODO: Make this one call
      // get list details
      request('POST', 'lists/' + list.ident + '/duplicate', null)
        .then((response) => {
          const data = response.data
          this.getListData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    numberIfy (item, index) {
      item.rating = parseFloat(item.rating)
      return item
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
<style>
  abbr[title] {
    text-decoration: underline dotted;
    border-bottom-width: 0px;
  }
</style>
