<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Edit list
        </p>
        <button class="delete" aria-label="close" @click="close" />
      </header>
      <section class="modal-card-body">
        <form class="ui form loginForm form-horizontal" @submit.prevent="beforeEditList">
          <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('name')}">
            <label for="title" class="col-sm-2 control-label">Title*</label>
            <div class="col-sm-10">
              <input
                v-model="name"
                v-validate="'required'"
                class="form-control"
                name="name"
                placeholder="Name"
                type="text"
                autocomplete="off"
              >
            </div>
            <span v-show="errors.has('name')" class="help-block text-center">{{ errors.first('name') }}</span>
          </div>
          <div class="form-group col-sm-12">
            <label for="title" class="col-sm-2 control-label">Phonics*</label>
            <div class="col-sm-10">
              <b-switch
                v-model="isPhonics"
                type="is-success"
              >
                <small v-if="isPhonics">Yes - N.B. Phonic spelling will be guessed by the system but should be checked.</small><small v-else>No</small>
              </b-switch>
            </div>
          </div>
          <div class="form-group col-sm-12">
            <label for="locale" class="col-sm-2 control-label">Locale *</label>
            <div class="col-sm-10">
              <select v-model="locale" class="form-control">
                <option value="en_US">
                  🇺🇸 American English
                </option>
                <option value="en_GB">
                  🇬🇧 British English
                </option>
                <option value="fr_FR">
                  🇫🇷 French
                </option>
                <option value="de_DE">
                  🇩🇪 German
                </option>
                <option value="pt_BR">
                  🇧🇷 Portuguese (Brazil)
                </option>
                <option value="pt_PT">
                  🇵🇹 Portuguese (Portugal)
                </option>
                <option value="es_ES">
                  🇪🇸 Spanish
                </option>
                <option value="cy_GB">
                  🏴󠁧󠁢󠁷󠁬󠁳󠁿 Welsh
                </option>
              </select>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.prevent="beforeEditList">
          Save changes
        </button>
        <button class="button" @click="close">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { Validator } from 'vee-validate'
import { request } from '@/edshed-common/api'
export default {
  name: 'EditList',
  props: ['list'],
  data (router) {
    return {
      locale: null,
      isPhonics: false,
      name: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.locale = this.list.locale
      this.name = this.list.title
      this.isPhonics = parseInt(this.list.is_phonics, 10) === 1
      const dictionary = {
        en_GB: {
          attributes: {
            name: 'Title',
            words: 'Words'
          }
        }
      }
      Validator.localize('en_GB', dictionary.en_GB)
    })
  },
  methods: {
    beforeEditList () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveList()
        }
      })
    },
    saveList () {
      request('POST', 'lists/' + this.list.ident, { title: this.name, locale: this.locale, phonics: (this.isPhonics ? 1 : 0) })
        .then((response) => {
          this.success()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Error'
        })
    },
    success () {
      this.$emit('success')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
